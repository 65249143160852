import './DateField.css';

const DateField = (props) => {
    return(
        <div className='datefield-container'>
            <div>{props.label}</div>
            <input type="date" id="fecha" name={props.name} value={props.value} onChange={props.onChange} />
        </div>
    );
}

export default DateField;