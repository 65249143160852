import React, { useState, useEffect } from 'react';
import Title from '../../../components/title/Title';
import './AdminHome.css';

const AdminHome = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: '',
        },
        loading: true,
        error: {}
    });

    useEffect(() => {
        setEstado(JSON.parse(sessionStorage.getItem('estado')));
        setEstado({
            ...estado,
            admin: JSON.parse(sessionStorage.getItem('adminUser'))
        });

    }, []);

    return(
        <div className='page-container'>
            <Title>Administracion del portal para proveedores</Title>
            <div className='page-body'>
                <div>
                    Navegue a través de las opciones de la izquierda.
                </div>
            </div>
        </div>
    );
}

export default AdminHome;