import React from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';

import './OtrosDocumentos.css';

const OtrosDocumentosView = (props) => {

    const { documentos } = props;

    return(
        <div className='page-container'>
            <Title>Otros documentos</Title>
            {documentos.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
                <div className='page-body'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>DEBITOS</th>
                                <th>CREDITOS</th>
                                <th>VER</th>
                                {/*<th>DESCARGAR</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                documentos.data.map(
                                    documento => {
                                        return(
                                            <tr key={documento.id}>
                                                <td>{ documento.tipoDoc + '-' + documento.numeroDoc }</td>
                                                <td>{ formatDateMin(new Date(documento.fechaDoc)) }</td>
                                                <td>${ formatNumber(documento.debitos) }</td>
                                                <td>${ formatNumber(documento.creditos) }</td>
                                                <td><div className='view-icon-container'><Link to={{ pathname: "/portal/otros-documentos-detalle", state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                {/*<td><div className='pdf-icon-container' ><Link style={{ color: 'red' }} onClick={(e) => { generarEgresoDetallePdf(e, egreso) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>*/}
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default OtrosDocumentosView;