import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Title from '../../../components/title/Title';
import { Spinner, Modal, Button, Row, Col } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faEdit } from '@fortawesome/free-regular-svg-icons';
import { httpGet, httpPost, httpDelete, httpPut } from '../../../http';
import ConfirmModal from '../../../components/modal/confirmModal/ConfirmModal';
import './CentrosOperacion.css';

const TextBox = (props) => {
    return(
        <input className='signup-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled} required />
    );
}

const EditModal = (props) => {
    if(props.show){
        return(
            ReactDOM.createPortal(
                <Modal size="large" show={props.show} onHide={props.onClose} onShow={() => {}}>
                    <Modal.Header closeButton>
                        <h3>Configurar centro de operación</h3>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <form>
                            <div>
                                <label style={{ width: '100px' }}>Codigo:</label>
                                <TextBox type="text" name="codigo" value={props.co.codigo} disabled={true} />
                            </div>
                            <div>
                                <label style={{ width: '100px' }}>Nombre:</label>
                                <TextBox type="text" name="nombre" value={props.co.nombre} onChange={props.onChange} />
                            </div>
                            <div>
                                <label style={{ width: '100px' }}>Visible:</label>
                                <Toggle onstyle='success' offstyle="danger" active={props.co.visible} onClick={props.onVisible} />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Button onClick={props.onAccept}>Aceptar</Button>
                            </Col>
                            <Col>
                                <Button onClick={props.onClose}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
                , document.getElementById('modal'))            
        );
    } else {
        return (
            null
        );
    }
}

const CentrosOperacion = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: '',
        },
        loading: true,
        error: {}
    });

    const [ cos, setCos ] = useState({
        data: [],
        loading: true,
        error: {},
        showModal: false
    });

    const [ co, setCo ] = useState({
        codigo: '',
        nombre: '',
        visible: true
    });

    const [ showEditModal, setshowEditModal ] = useState(false);

    useEffect(() => {
        setEstado(JSON.parse(sessionStorage.getItem('estado')));
        setEstado({
            ...estado,
            admin: JSON.parse(sessionStorage.getItem('adminUser'))
        });

        getCentrosOperacion();

    }, []);

    const getCentrosOperacion = () => {
        setCos({
            ...cos,
            loading: true
        });
        httpGet('/api/v1/centro-operacion/conf').then(
            resp => {
                console.log(resp.data);
                setCos({
                    ...cos,
                    data: resp.data,
                    loading: false
                })
            }
        ).catch(
            error => {
                console.log(error);
                setCos({
                    ...cos,
                    loading: false
                });
            }
        );

    }

    const closeModal = () => {
        setshowEditModal(false);
    }

    const handleModalEdit = (e, co) => {
        e.preventDefault();
        setCo(co);
        setshowEditModal(true);
    }

    const handleVisible = () => {
        setCo({
            ...co,
            visible: !co.visible
        });
    }

    const handleChange = (e) => {
        setCo({
            ...co,
            [e.target.name]: e.target.value
        });
    }

    const handleSave = () => {
        console.log("NOMBRE");
        console.log(co.nombre);
        httpPost('/api/v1/centro-operacion', co).then(
            resp => {
                setshowEditModal(false);
                getCentrosOperacion();
            }
        ).catch(
            error => {
                console.log(error);
                setshowEditModal(false);
            }
        );
    }

    return(
        <div className='page-container'>
            <Title>Configuración de centros de operación</Title>
            <div className='page-body'>
            </div>
            <div>
                { cos.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CODIGO</th>
                            <th>NOMBRE</th>
                            <th>VISIBLE</th>
                            <th>EDITAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            cos.data.map(
                                co => {
                                    return(
                                        <tr key={co.codigo}>
                                            <td>{ co.codigo }</td>
                                            <td>{ co.nombre }</td>
                                            <td>{ co.visible ? <div className='visible-icon-container'><FontAwesomeIcon className='view-icon' icon={faEye} /></div> : <div className='not-visible-icon-container'><FontAwesomeIcon className='view-icon' icon={faEyeSlash} /></div> }</td>
                                            <td><div className='visible-icon-container'><Link style={{ color: 'black' }} to='' onClick={(e) => { handleModalEdit(e, co) }}><FontAwesomeIcon className='view-icon' icon={faEdit} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
            <EditModal show={ showEditModal } onAccept={ handleSave } onClose={ closeModal } co={co} onVisible={handleVisible} onChange={handleChange} />
        </div>
    );
}

export default CentrosOperacion;