import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './EgresoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
 

const DocumentoFinancieroDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [egreso, setEgreso ] = useState(
        {
            encabezado: {
                rowid: '',
                idCo: '',
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                totalDb: 0,
                totalCr: 0
            },
            retenciones: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0,
                totalBase: 0
            },
            movimiento: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0
            },
            documentos: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.egreso){
            props.history.push('/portal');
            return 0;
        }
        
        getEgresoDetalle(props.history.location.state.egreso, newEstado.proveedor);

    }, []);

    useEffect(() => {
        console.log(egreso);
    }, [egreso]);

    const getEgresoDetalle = async (egreso2, proveedor) => {
        setEgreso({
            ...egreso,
            loading: true
        });

        const documento = await httpGet('/api/v1/egresos/documento-fi/' + egreso2.saldoAbiertoRowid);
        const movimiento = await httpGet('/api/v1/egresos/documento-fi-detalle/' + egreso2.saldoAbiertoRowid + '/' + proveedor.nit);
        /*const documentos = await httpGet('/api/v1/egresos/documentos/' + proveedor.nit + '/' + egreso2.rowid);*/
        /*const retenciones = await httpGet('/api/v1/egresos/retenciones/' + egreso2.docCruRowid);*/

        if((documento !== null) && (documento !== undefined)){
            setEgreso({
                ...egreso,
                encabezado: documento.data,
                movimiento: movimiento.data,
                /*retenciones: retenciones.data,*/
                /*documentos: documentos.data,*/
                loading: false
            });
        }
        
    }
    

    const generarEgresoDetallePdf = async () => {

        let data = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: egreso.encabezado,
            retenciones: egreso.retenciones,
            movimiento: egreso.movimiento,
            documentos: egreso.documentos
        }

        console.log(data);

        props.history.push({ pathname: '/portal/documento-financiero-pdf', state: { egreso: data } });
    }

    return(
        <div className='page-container'>
            <Title>Detalle Documento</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Encabezado del docuento</SubTitle>
                {egreso.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                <> 
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>CO</th>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>VALOR DEBITOS</th>                                                        
                                <th>VALOR CREDITOS</th>                                                        
                                <th>DESCARGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ egreso.encabezado.idCo }</td>
                                <td>{ egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numeroDoc }</td>
                                <td>{ formatDateMin(new Date(egreso.encabezado.fechaDoc)) }</td>
                                <td>${ formatNumber(egreso.encabezado.totalDb) }</td>
                                <td>${ formatNumber(egreso.encabezado.totalCr) }</td>
                                <td><div className='pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <SubTitle>Movimiento contable</SubTitle>
                    <table className='info-table doc-table'>
                        <thead>
                            <tr>
                                <th>REG.</th>
                                <th>CODIGO CUENTA</th>
                                <th>DESCRIPCION CUENTA</th>
                                <th>CO</th>
                                <th>TERCERO</th>
                                <th>RAZON SOCIAL</th>
                                <th>DEBITOS</th>
                                <th>CREDITOS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                egreso.movimiento.detalles.map(
                                    (detalle, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{ (index + 1) }</td>
                                                <td>{ detalle.cuentaId }</td>
                                                <td>{ detalle.cuentaDescripcion }</td>
                                                <td>{ detalle.idCo }</td>
                                                <td>{ detalle.nit }</td>
                                                <td>{ detalle.razonSocial }</td>
                                                <td>${ formatNumber(detalle.valorDb) }</td>
                                                <td>${ formatNumber(detalle.valorCr) }</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                            {/*<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>SUMAS IGUALES</td>
                                <td>${ formatNumber(egreso.movimiento.totalDebito) }</td>
                                <td>${ formatNumber(egreso.movimiento.totalCredito) }</td>
                            </tr>*/}
                        </tbody>
                    </table>
                    <br />
                    <br />
                </>
                }
            </div>
        </div>
    );
}

export default DocumentoFinancieroDetalle
