import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './EgresoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
 

const EgresoDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [egreso, setEgreso ] = useState(
        {
            encabezado: {
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                fechaVcto: '',
                valor: '',
                debitos: '',
                creditos: '',
                detalle: '',
            },
            detalles: [],
            retenciones: [],
            totalRetenciones: 0,
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.egreso){
            props.history.push('/portal');
            return 0;
        }
        
        getEgresoDetalle(props.history.location.state.egreso);

    }, []);

    const getEgresoDetalle = async (egreso2) => {
        setEgreso({
            ...egreso,
            loading: true
        });

        const companyId = sessionStorage.getItem('companyId');
        
        const encabezado = await httpGet('/api/v1/egresos/encabezado/' + egreso2.idCo + '/' + egreso2.tipoDoc + '/' + egreso2.numDoc);
        const retenciones = await httpGet('/api/v1/egresos/retenciones/' + egreso2.idCo + '/' + egreso2.tipoDoc + '/' + egreso2.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documentos/' + egreso2.idCo + '/' + egreso2.tipoDoc + '/' + egreso2.numDoc);
        console.log(encabezado);
        console.log(detalles);
        console.log(retenciones);

        let totalRetenciones = 0;
        
        for( const retencion of retenciones.data){
            totalRetenciones = totalRetenciones + retencion.valor;
        }

        setEgreso({
            ...egreso,
            encabezado: encabezado.data[0],
            retenciones: retenciones.data,
            totalRetenciones: totalRetenciones,
            detalles: detalles.data,
            loading: false
        });
    }

    const generarEgresoDetallePdf = async () => {

        let newEgreso = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: egreso.encabezado,
            retenciones: egreso.retenciones,
            detalles: egreso.detalles
        }

        console.log(newEgreso);

        props.history.push({ pathname: '/portal/egreso-pdf', state: { egreso: newEgreso } });
    }

    const goToDetalle = (doc) => {
        if (doc.docCM) {
            props.history.push({ pathname: '/portal/documentos-detalle', state: { documento: doc } });
        } else {
            let data = {
                ...doc
            }
            data.tipoDoc = doc.tipoDocCruce;
            data.numDoc = doc.numDocCruce;

            props.history.push({ pathname: '/portal/nota-contable-detalle', state: { documento: data } });
        }
    }

    return(
        <div className='page-container'>
            <Title>Detalle de egreso</Title>
            <div className='page-body'>
                <SubTitle>Empresa: {estado.empresa.razonSocial}</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del egreso</SubTitle>
                {egreso.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            {/*<th>FECHA DE VENCIMIENTO</th>*/}
                            <th>VALOR DEL DOCUMENTO</th>                                                        
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>DETALLE</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ egreso.encabezado.idCo + '-' + egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numDoc }</td>
                            <td>{ formatDateMin(new Date(egreso.encabezado.fechaDoc)) }</td>
                            {/*<td>{ egreso.encabezado.fechaVcto }</td>*/}
                            <td>${ formatNumber(egreso.encabezado.valor) }</td>
                            <td>${ formatNumber(egreso.encabezado.debitos) }</td>
                            <td>${ formatNumber(egreso.encabezado.creditos) }</td>
                            <td>{ egreso.encabezado.notas }</td>
                            <td><div className='pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Retenciones</SubTitle>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CUENTA</th>
                            <th>DESCRIPCION</th>
                            <th>TASA</th>
                            <th>BASE</th>                                                        
                            <th>VALOR</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.retenciones.map(retencion => {
                                return(
                                <tr>
                                    <td>{ retencion.cuenta }</td>
                                    <td>{ retencion.cuentaDescripcion }</td>
                                    <td>{ formatNumber(retencion.tasa) }%</td>
                                    <td>${ formatNumber(retencion.valorBase) }</td>
                                    <td>${ formatNumber(retencion.valor) }</td>
                                </tr>
                                );
                            })
                        }
                        <tr>
                            <td>TOTAL:</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>${ formatNumber(egreso.totalRetenciones) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CENTRO DE OPERACIÓN</th>
                            <th>CUENTA</th>
                            <th>DESCRIPCIÓN CUENTA</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{(index + 1)}</td>
                                            <td>{ detalle.coMov } - { detalle.coDescripcion }</td>
                                            <td>{ detalle.idCuenta }</td>
                                            <td>{ detalle.descripcionCuenta }</td>
                                            <td>{ detalle.idCuenta.startsWith('13') ? detalle.tipoDocCruce + '-' + detalle.numDocCruce : detalle.tipoDocProv + '-' + detalle.numDocProv }</td>
                                            <td>${ formatNumber(detalle.debitos) }</td>
                                            <td>${ formatNumber(detalle.creditos) }</td>
                                            <td>{ detalle.idCuenta.substring(0, 2) === '22' && <div className='view-icon-container' onClick={ () => {goToDetalle(detalle)} } ><FontAwesomeIcon className='view-icon' icon={faEye} /></div>}</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                </>
                }
            </div>
        </div>
    );
}

export default EgresoDetalle;
