import React from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';

const OtrosDocumentosView = (props) => {

    const { estado, documento, generarDocumentoDetallePdf } = props;

    return(
        <div className='page-container'>
            <Title>Detalle del documento</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Movimiento del documento</SubTitle>
                {documento.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CO</th>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>DEBITOS</th>                                                        
                            <th>CREDITOS</th>                                                        
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ documento.encabezado.idCo }</td>
                            <td>{ documento.encabezado.tipoDoc + '-' + documento.encabezado.numeroDoc }</td>
                            <td>{ formatDateMin(new Date(documento.encabezado.fechaDoc)) }</td>
                            <td>${ documento.encabezado.debitos === undefined ? 0 : formatNumber(documento.encabezado.debitos) }</td>
                            <td>${ documento.encabezado.creditos === undefined ? 0 : formatNumber(documento.encabezado.creditos) }</td>
                            <td><div className='pdf-icon-container' onClick={generarDocumentoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Movimiento contable</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CODIGO CUENTA</th>
                            <th>DESCRIPCION CUENTA</th>
                            <th>CO</th>
                            <th>TERCERO</th>
                            <th>RAZON SOCIAL</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documento.movimiento.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ detalle.cuentaId }</td>
                                            <td>{ detalle.cuentaDescripcion }</td>
                                            <td>{ detalle.idCo }</td>
                                            <td>{ detalle.nit }</td>
                                            <td>{ detalle.razonSocial }</td>
                                            <td>${ formatNumber(detalle.valorDb) }</td>
                                            <td>${ formatNumber(detalle.valorCr) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>SUMAS IGUALES</td>
                            <td>${ formatNumber(documento.movimiento.totalDebito) }</td>
                            <td>${ formatNumber(documento.movimiento.totalCredito) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Documentos</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CO</th>
                            <th>DOC. CRUCE</th>
                            <th>DETALLE</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            {/*<th>DESCUENTOS</th>
                            <th>RETENCIONES</th>
                            <th>VALOR FACTURA</th>}
                            <th>VER</th>
                            <th>DESCARGAR</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documento.documentos.map(
                                (doc, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ doc.docIdCoMov + ' ' + doc.docCoDescripcion }</td>
                                            <td>{ doc.docCruTipoDoc + ' ' + doc.docCruNumeroDoc }</td>
                                            <td>{ doc.notas }</td>
                                            <td>${ formatNumber(doc.saldoAbiertoValorDb) }</td>
                                            <td>${ formatNumber(doc.saldoAbiertoValorCr) }</td>
                                            {/*<td>${ doc.docCruTipoDoc.substring(0,1) === 'N' ? (formatNumber(doc.docValorNeto * -1)) : formatNumber(doc.docValorNeto) }</td>*/}
                                            {/*<td>${ formatNumber(doc.factDscto) }</td>
                                            <td>${ formatNumber(doc.factValorRetencion) }</td>
                                            <td>${ formatNumber(doc.docValorNeto) }</td>
                                            <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-detalle', state: { documento: doc } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            <td><div className='pdf-icon-container' onClick={generarDocumentoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>*/}
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                {/*<br />
                <br />
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CENTRO DE OPERACION</th>
                            <th>CUENTA</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.detalles.map(
                                detalle => {
                                    return(
                                        <tr key={detalle.id}>
                                            <td>{detalle.id}</td>
                                            <td>{ detalle.coDocumento }</td>
                                            <td>{ detalle.idCuenta }</td>
                                            <td>{ detalle.tipoDocumentoProveedor + '-' + detalle.numeroDocumentoProveedor }</td>
                                            <td>${ formatNumber(detalle.debitos) }</td>
                                            <td>${ formatNumber(detalle.creditos) }</td>
                                            <td>{ detalle.idCuenta.substring(0, 2) === '22' && <div className='view-icon-container'><Link to={{ pathname: '/portal/documentos-detalle', state: { detalle: detalle } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                    */}
                </>
                }
            </div>
        </div>
    );
}

export default OtrosDocumentosView;