import React, { useState, useEffect } from 'react';
import FacturasView from './FacturasView';
import { httpGet } from '../../http';
import { formatDateQuery } from '../../misc';

const Facturas = (props) => {

    const [ request, setRequest ] = useState({
        loading: false,
        error: false,
        errorMsg: ''
    });

    const [ dates, setDates ] = useState({
        date1: '',
        date2: ''
    });

    const handleChange = (e) => {
        setDates({
            ...dates,
            [e.target.name]: e.target.value
        });
    }

    const handleClick = () => {
        if((dates.date1.length > 0) && (dates.date2.length > 0)){
            getFacturas();
        } else {
            alert("Por favor ingrese las fechas correctamente.")
        }
    }

    const [ facturas, setFacturas ] = useState([]);

    useEffect(() => {
        //getFacturas();
    }, []);    

    const getFacturas = async () => {

        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });

        const estado = JSON.parse(sessionStorage.getItem('estado'));
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(estado);
        const reqFacturas = await httpGet('/api/v1/facturas?nit=' + proveedor.nit + '&fecha1=' + dates.date1.replaceAll('-', '') + '&fecha2=' + dates.date2.replaceAll('-', ''));
        console.log(reqFacturas);
        if(reqFacturas.data){
            setFacturas(reqFacturas.data);
        }

        setRequest({
            ...request,
            loading: false,
            error: false,
            errorMsg: ''
        });
    }

    const handleViewClick = (e, factura) => {
        e.preventDefault();
        const egreso = {
            idCo: factura.docFCCo,
            tipoDoc: factura.docFCTipo,
            numDoc: factura.docFCNum
        };

        console.log(egreso);

        props.history.push({ pathname: '/portal/egreso-detalle', state: { egreso: egreso } });
    }

    return <FacturasView request={request} facturas={facturas} onViewClick={handleViewClick} dates={dates} onChange={handleChange} onClick={handleClick} />
}

export default Facturas;