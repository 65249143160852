import React, { useState, useEffect } from 'react';
import './Login.css';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { Alert, Spinner } from 'react-bootstrap';
import { httpPost } from '../../http';
import { Link } from 'react-router-dom';
const TextBox = (props) => {
    return(
        <input className='login-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const Login = (props) => {

    const { history: { location: { state: reg } } } = props
    const { history: { location: { state: recovery } } } = props

    const [ estado, setEstado ] = useState({
        
    });

    const [ login, setLogin ] = useState({
        nit: '',
        password: '',
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        
        console.log(reg);
        console.log(recovery);
        
        if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }

        setEstado(JSON.parse(sessionStorage.getItem('estado')));

    }, []);

    const handleChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLogin({
            ...login,
            error: false,
            errorMsg: ''
        });

        try{
            const proveedor = await httpPost('/api/v1/proveedor/login', login);
            if(proveedor.data){
                sessionStorage.setItem('proveedor', JSON.stringify(proveedor.data));
                props.history.push('/portal');
                return 0;
            }
        } catch(error){
            console.log(error);
            if(error.response.status){
                if(error.response.status === 403 ){
                    setLogin({
                        ...login,
                        error: true,
                        errorMsg: 'N.I.T. o contraseña incorrectos.'
                    });
                    return 0;
                }
            }
            setLogin({
                ...login,
                error: true,
                errorMsg: 'Ocurrio un error al ingresar.'
            });
        }
    }

    return(
        <div>
            <div className='login-content'>
                <div className='login-body'>
                    { props.history.location.state && props.history.location.state.reg &&
                        <Alert variant='success'>Se ha registrado correctamente, por favor ingrese.</Alert>
                    }
                    { props.history.location.state && props.history.location.state.recovery &&
                        <Alert variant='success'>Se ha enviado la nueva contraseña, por favor revise su correo</Alert>
                    }
                    { login.error &&
                        <Alert variant='danger'>{ login.errorMsg }</Alert>
                    }
                    <div>
                        <h2 className='login-h2'>Ingreso a la plataforma</h2>
                    </div>
                    <div>
                        <p className='login-p'>Ingrese su N.I.T. y su contraseña para acceder</p>
                    </div>
                    <form className='login-form' onSubmit={ handleSubmit }>
                        <div className='login-field'>
                            <div className='login-field-name'>NIT:</div>
                            <TextBox type='text' name='nit' value={login.nit} onChange={handleChange} />
                        </div>
                        <div className='login-field'>
                            <div className='login-field-name'>Contraseña:</div>
                            <TextBox type='password' name='password' value={login.password} onChange={handleChange} />
                        </div>
                        <br />
                        <Button type='submit'>Ingresar</Button>
                    </form>
                </div>
                <div className='login-password-recovery'>
                        <Link style={{ color: 'blue' }} to='/recovery-password'>¿Olvido su contraseña?</Link>
                </div>
            </div>
        </div>
    );
}

export default Login;