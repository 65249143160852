import React, { useState, useEffect } from 'react';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { httpGet, httpPost } from '../../http';
import { Alert, Spinner } from 'react-bootstrap';
import './Signup.css';

const TextBox = (props) => {
    return(
        <input className='signup-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const Field = (props) => {
    return(
        <div className='signup-field'>
            <div className='signup-field-name'>{ props.label }</div>
            <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} /></div>
            <div className='signup-field-hint'>{ props.hint }</div>
        </div>
    );
}


const Signup = (props) => {

    const [ estado, setEstado ] = useState({
        
    });

    const [ form, setForm ] = useState({
        razonSocial: '',
        nit: '',
        email: '',
        email2: '',
        cel: '',
        password1: '',
        password2: '',
        error: false,
        errorMsg: '',
        loading: false
    });

    const [ tyc, setTyc ] = useState(false);

    useEffect(() => {
        /*if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }*/

        setEstado(JSON.parse(sessionStorage.getItem('estado')));

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{

            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: true
            });
    
            if (await valNit(form.nit)) {
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El N.I.T. ya se encuentra registrado.',
                    loading: false
                });
                return 0;
            }

            if (await valEmail(form.email)) {
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El Correo electrónico ya se encuentra registrado.',
                    loading: false
                });
                return 0;
            }

            /*const r = await valTercero(form.nit);
            console.log("VAL TERCERO");
            console.log(r);
            if(!r){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El N.I.T. no se encuentra registrado en nuestro sistema.',
                    loading: false
                });
                return 0;
            }*/
                
            if (form.email !== form.email2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Los correos electrónicos no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            if (form.password1 !== form.password2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Las contraseñas no coinciden.',
                    loading: false
                });
                return 0;
            }

            const proveedor = {
                nit: form.nit,
                razonSocial: form.razonSocial,
                email: form.email,
                tel: form.cel,
                password: form.password1,
                state: true
            }
    
            const newProveedor = await httpPost('/api/v1/proveedor', proveedor);
            if(newProveedor.data){
                console.log('PROVEEDOR CREADO');
                props.history.push({ pathname: '/login', state: { reg: true } });
                return 0;
            }
    
            
            
            console.log('FORMULARIO VALIDADO');
            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: false
            });
        } catch(error){
            setForm({
                ...form,
                error: true,
                errorMsg: 'Ocurrio un error al registrarse.'
            });
        };

    }

    const handleChange = (e) => {

        if(e.target.name === 'nit'){
            setForm({
                ...form,
                [e.target.name]: e.target.value.replace(/[^0-9]/g,'')
            });    
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            });
        }
    }

    const valNit = async (nit) => {
        try{
            const val = await httpGet('/api/v1/proveedor/nit/' + nit);
        } catch(error){
            if(error.response.status){
                if(error.response.status === 404 ){
                    return false;        
                }
            }
        }
        
        return true;
    }

    
    const valEmail = async (email) => {
        try{
            const val = await httpGet('/api/v1/proveedor/email/' + email);
        } catch(error){
            if(error.response.status){
                if(error.response.status === 404 ){
                    return false;        
                }
            }
        }
        return true;
    }

    const valTercero = async (nit) => {
        try{
            const tercero = await httpGet('/api/v1/terceros/nit/' + nit);
            console.log("TERCERO DATA");
            console.log(tercero.data);
            if(tercero.data){
                return true;
            }
        } catch(error){
            return false;
        }

        return false;
    }

    const handleTycLink = () => {
        window.open('http://downloads.aldetec.co/politica-proteccion-de-datos.pdf', "_blank");
    }

    return(
        <div>
            <div className='signup-content'>
                <div className='signup-body'>
                    <div>
                        <h2 className='signup-h2'>Registro de proveedor</h2>
                    </div>
                    <div>
                        <p className='signup-p'>Por favor llene los campos con asterisco(*)</p>
                        <InfoBox>Digite los campos obligatorios(*) para crear un nuevo proveedor</InfoBox>
                        { form.error &&
                            <>
                                <br />
                                <Alert variant='danger'>{ form.errorMsg }</Alert>
                            </>
                        }
                    </div>
                    <form className='signup-form' onSubmit={handleSubmit} >
                        <Field type='text' label='Razón social(*)' hint='Razón social del proveedor' name='razonSocial' value={form.razonSocial} onChange={handleChange} />
                        <Field type='text' label='N.I.T.(*)' hint='NIT sin digito de verificación ni puntos' name='nit' value={form.nit} onChange={handleChange} />
                        <Field type='email' label='Correo electrónico(*)' hint='digite su correo electrónico' name='email' value={form.email} onChange={handleChange} />
                        <Field type='email' label='Repita su Correo electrónico(*)' hint='digite de nuevo su correo electrónico' name='email2' value={form.email2} onChange={handleChange} />
                        <Field type='text' label='Numero celular(*)' hint='digite su numero celular' name='cel' value={form.cel} onChange={handleChange} />
                        <Field type='password' label='Contraseña(*)' hint='digite una contraseña' name='password1' value={form.password1} onChange={handleChange} />
                        <Field type='password' label='Repita la contraseña(*)' hint='digite de nuevo la contraseña' name='password2' value={form.password2} onChange={handleChange} />
                        {/*<div className="signup-tyc-field">
                            <input type="checkbox" value={tyc} onChange={() => { setTyc(!tyc); }} />
                            <div className="signup-tyc-label">Ha leído y acepta nuestra política de tratamiento y procedimientos en materia de protección de datos personales <button className="signup-tyc-link" onClick={handleTycLink} >(Ver nuestras politicas)</button></div>
                        </div>*/}
                        { form.loading &&
                            <>
                                <br />
                                <Spinner animation="border" variant="primary" />
                            </>
                        }
                        { !form.loading &&
                            <>
                                <br />
                                <Button type='submit'>Registrarse</Button>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signup;