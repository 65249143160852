import React from 'react';
import Title from '../../../components/title/Title';
import Button from '../../../components/button/Button';
import { httpGet, httpPut, httpPost } from '../../../http';
import { Alert, Spinner } from 'react-bootstrap';
import './Configuracion.css';

const TextBox = (props) => {
    return(
        <input className='profile-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={props.disabled} />
    );
}

const Field = (props) => {
    return(
        <div className='profile-field'>
            <div className='profile-field-name'>{ props.label }</div>
            <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={ props.disabled } /></div>            <div className='profile-field-hint'>{ props.hint }</div>
        </div>
    );
}

const ConfiguracionView = (props) => {

    const { conf, onChange, request, reqConf, onSubmit } = props

    return(
        
        <div className='page-container'>
            <Title>Configuración</Title>
            { request.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>                
            :    
                <>
                    <br />
                    { reqConf.success && <Alert variant='success'>La configuracion se ha guardado.</Alert> }
                    { reqConf.error && <Alert variant='danger'>Ha ocurrido un error al guardar la configuración{ ':' + reqConf.errorMsg }</Alert> }
                    <div className='page-body'>
                        <form className='signup-form' onSubmit={onSubmit} >
                            {/*<Field type='text' label='Nombre del sitio(*)' hint='Nombre del sitio' name='siteName' value={configuracion.siteName} onChange={onChange} required={ true } />*/}
                            <Field type='number' label='Dia de disponibilidad de los certificados del mes anterior' hint='Dia de disponibilidad' name='certDay' value={conf.certDay} onChange={onChange} required={ true } />
                            <br />
                            <Button type='submit'>Aceptar</Button>
                        </form>
                    </div>
                </>
            }
        </div>
    );
}

export default ConfiguracionView;