import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import './ConfirmModal.css';
import 'bootstrap/dist/css/bootstrap.css';

const ConfirmModal = (props) => {
    if(props.show){
        return(
            ReactDOM.createPortal(
                <Modal size="" show={props.show} onHide={props.onClose} onShow={() => {}}>
                    <Modal.Header closeButton>
                        <h3>{ props.title }</h3>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        { props.children }
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Button onClick={props.onAccept}>Si</Button>
                            </Col>
                            <Col>
                                <Button onClick={props.onClose}>No</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
                , document.getElementById('modal'))            
        );
    } else {
        return (
            null
        );
    }
}

export default ConfirmModal;