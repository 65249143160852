import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Title from '../../../components/title/Title';
import { Spinner, Modal, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faEye} from '@fortawesome/free-regular-svg-icons';
import ConfirmModal from '../../../components/modal/confirmModal/ConfirmModal';
import { httpGet, httpPut, httpPost, httpDelete } from '../../../http';
import { formatDate } from '../../../misc';
import './Proveedores.css';

const TextBox = (props) => {
    return(
        <input className='admins-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const Field = (props) => {
    return(
        <div className='admins-field'>
            <div className='admins-field-name'>{ props.label }</div>
            <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} /></div>
            <div className='admins-field-hint'>{ props.hint }</div>
        </div>
    );
}

const EditModal = (props) => {
    if(props.show){
        return(
            ReactDOM.createPortal(
                <Modal size="xl" show={props.show} onHide={props.onClose} onShow={() => {}}>
                    <Modal.Header closeButton>
                        <h3>{props.title}</h3>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <form className='signup-form' >
                            <Field type='text' label='Razon social' hint='Razon social del proveedor' name='razonSocial' value={props.proveedor.razonSocial} onChange={props.onChange} />
                            <Field type='text' label='N.I.T.' hint='Dijite el numero de NIT del proveedor' name='nit' value={props.proveedor.nit} onChange={props.onChange} />
                            <Field type='email' label='Correo electronico' hint='Dijite el correo electronico del proveedor' name='email' value={props.proveedor.email} onChange={props.onChange} />
                            <Field type='text' label='Numero celular' hint='Dijite el numero celular del proveedor' name='tel' value={props.proveedor.tel} onChange={props.onChange} />
                            <Field type='password' label='Contraseña' hint='Dijite una contraseña para el proveedor' name='password1' value={props.proveedor.password1} onChange={props.onChange} />
                            <Field type='password' label='Repita la contraseña' hint='Dijite de nuevo la contraseña' name='password2' value={props.proveedor.password2} onChange={props.onChange} />
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Button onClick={props.onAccept}>Aceptar</Button>
                            </Col>
                            <Col>
                                <Button onClick={props.onClose}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
                , document.getElementById('modal'))            
        );
    } else {
        return (
            null
        );
    }
}

const Proveedores = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: '',
        },
        loading: true,
        error: {}
    });

    const [ proveedores, setProveedores ] = useState({
        data: [],
        loading: true,
        error: {},
        showModal: false
    });

    const [ proveedor, setProveedor ] = useState({
        _id: '',
        nit: '',
        razonSocial: '',
        email: '',
        tel: '',
        password1: '',
        password2: '',
        createAt: '',
        state: false,
        error: false,
        errorMsg: '',
        loading: false,
        success: false
    });

    const [ showModal, setShowModal ] = useState(false); 
    const [ showEditModal, setShowEditModal ] = useState(false);

    useEffect(() => {
        setEstado(JSON.parse(sessionStorage.getItem('estado')));
        setEstado({
            ...estado,
            admin: JSON.parse(sessionStorage.getItem('adminUser'))
        });

        getProveedores();

    }, []);

    const getProveedores = () => {
        setProveedores({
            ...proveedores,
            loading: true
        });
        httpGet('/api/v1/proveedor').then(
            resp => {
                console.log(resp.data);
                setProveedores({
                    ...proveedores,
                    data: resp.data,
                    loading: false
                })
            }
        ).catch(
            error => {
                console.log(error);
                setProveedores({
                    ...proveedores,
                    loading: false
                });
            }
        );

    }

    const handleModalRemove = (e, proveedor) => {
        setProveedor(proveedor);
        setShowModal(true);
    }

    const handleRemove = () => {
        httpDelete('/api/v1/proveedor/' + proveedor._id).then(
            resp => {
                setShowModal(false);
                console.log(resp.data);
                getProveedores();
            }
        ).catch(
            error => {
                console.log(error);
                setShowModal(false);
            }
        );
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const handleEdit = (e, proveedorM) => {
        setProveedor({
            ...proveedor,
            _id: proveedorM._id,
            nit: proveedorM.nit,
            razonSocial: proveedorM.razonSocial,
            email: proveedorM.email,
            tel: proveedorM.tel
        });
        setShowEditModal(true);
    }

    const handleSave = () => {

        if (proveedor.password1 !== proveedor.password2){
            setProveedor({
                ...proveedor,
                error: true,
                errorMsg: 'Las contraseñas no coinciden.',
                loading: false
            });
            return 0;
        }        
        
        const pro = {
            _id: proveedor._id,
            nit: proveedor.nit,
            razonSocial: proveedor.razonSocial,
            email: proveedor.email,
            tel: proveedor.tel,
            password: proveedor.password1
        }

        httpPost('/api/v1/proveedor', pro).then(
            resp => {
                setProveedor({
                    ...proveedor,
                    error: false,
                    errorMsg: '',
                    loading: false,
                    success: true
                });
                setShowEditModal(false);
                getProveedores();
            }
        ).catch(
            error => {
                console.log(error);
                setShowEditModal(false);
            }
        );
    }

    const closeEditModal = () => {
        setShowEditModal(false);
    }

    const handleChange = (e) => {
        setProveedor({
            ...proveedor,
            [e.target.name]: e.target.value
        });
    }

    const handleExplore = (e, proveedor) => {
        localStorage.setItem("nitProveedor", proveedor.nit);
        window.open(process.env.PUBLIC_URL + '/portal', "_blank");
    }

    const valEmail = async (email) => {
        const val = await httpGet('/api/v1/proveedor/email/' + email);
        return val
    }

    return(
        <div className='page-container'>
            <Title>Administracion de proveedores</Title>
            <div className='page-body'>
            </div>
            <div>
                { proveedores.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>N.I.T.</th>
                            <th>RAZON SOCIAL</th>
                            <th>CORREO ELECTRÓNICO</th>
                            <th>NUMERO TEL.</th>
                            <th>FECHA DE REGISTRO</th>
                            <th>EXAMINAR</th>
                            <th>MODIFCAR</th>
                            <th>ELIMINAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            proveedores.data.map(
                                proveedor => {
                                    return(
                                        <tr key={proveedor.id}>
                                            <td>{ proveedor.nit }</td>
                                            <td>{ proveedor.razonSocial }</td>
                                            <td>{ proveedor.email }</td>
                                            <td>{ proveedor.tel }</td>
                                            <td>{ formatDate(new Date(proveedor.createAt)) }</td>
                                            <td><div className='visible-icon-container'><Link style={{ color: 'black' }} onClick={(e) => { handleExplore(e, proveedor) }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            <td><div className='visible-icon-container'><Link style={{ color: 'black' }} onClick={(e) => { handleEdit(e, proveedor) }}><FontAwesomeIcon className='view-icon' icon={faEdit} /></Link></div></td>
                                            <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { handleModalRemove(e, proveedor) }} ><FontAwesomeIcon className='view-icon' icon={faTrashAlt} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
            <ConfirmModal title='Eliminar proveedor' show={ showModal } onAccept={ handleRemove } onClose={ closeModal }>¿Desea eliminar el proveedor { proveedor.razonSocial }?</ConfirmModal>
            <EditModal title='Modificar proveedor' show={ showEditModal } onAccept={ handleSave } onClose={ closeEditModal } proveedor={proveedor} onChange={handleChange} />
        </div>
    );
}

export default Proveedores;