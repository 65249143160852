import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Title from '../../../components/title/Title';
import { Spinner, Modal, Button, Row, Col } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faEdit } from '@fortawesome/free-regular-svg-icons';
import { httpGet, httpPost, httpPut } from '../../../http';
import { getYear } from '../../../misc';
import './Lapsos.css';

const Lapsos = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: '',
        },
        loading: true,
        error: {}
    });

    const [ lapsos, setLapsos ] = useState({
        data: [],
        loading: true,
        error: {},
        showModal: false
    });

    useEffect(() => {
        setEstado(JSON.parse(sessionStorage.getItem('estado')));
        setEstado({
            ...estado,
            admin: JSON.parse(sessionStorage.getItem('adminUser'))
        });

        getLapsos();

    }, []);

    const getLapsos = () => {
        setLapsos({
            ...lapsos,
            loading: true
        });
        httpGet('/ProveedoresWSNEW/api/PeriodoReteIVA').then(
            resp => {
                console.log(resp.data);
                setLapsos({
                    ...lapsos,
                    data: resp.data,
                    loading: false
                })
            }
        ).catch(
            error => {
                console.log(error);
                setLapsos({
                    ...lapsos,
                    loading: false
                });
            }
        );

    }

    const handleVisible = (lapso) => {
        let newLapso = {...lapso};
        newLapso.visible = !newLapso.visible;
        httpPut('/ProveedoresWSNEW/api/PeriodoReteIVA', newLapso).then(
            resp => {
                getLapsos();
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    return(
        <div className='page-container'>
            <Title>Configuración de periodos de ReteIVA</Title>
            <div className='page-body'>
            </div>
            <div>
                { lapsos.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>AÑO</th>
                            <th>DESCRIPCION</th>
                            <th>VISIBLE</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            lapsos.data.map(
                                lapso => {
                                    return(
                                        <tr key={lapso.codigo}>
                                            <td>{ lapso.anno ? getYear(0) : getYear(1) }</td>
                                            <td>{ lapso.descripcion }</td>
                                            <td><Toggle onstyle='success' offstyle="danger" active={lapso.visible} onClick={() => handleVisible(lapso) } /></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
        </div>
    );
}

export default Lapsos;