import React, { useState } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './NotaDetalle.css';

const NotaDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '999999999',
            razonSocial: 'Caribe S.A.'
        },
        usuario: {
            nit: '94481222',
            razonSocial: 'Alexander Lopez'
        }
    });

    const [nota, setNota ] = useState(
        {
            encabezado: {
                co: '003',
                tipoDocumento: 'NP',
                numeroDocumento: '112925',
                fechaDoc: '20210222',
                detalle: 'MENOR VALOR FACTURADO',
                terceroDescripcion: 'UNILEVER ANDINA DE COLOMBIA L.T.D.A.',
                documentoProveedor: 'PMP-336466',
                totalBruto: '$-97.00',
                descuentos: '$0.00',
                impuestos: '$-18.00',
                totalNeto:'$-115.00'
            },
            detalles: [
                {
                    idItem: '021915',
                    descripcionItem: 'GEL EGO 240ml EXTREME MX',
                    cantidad: '0',
                    unidadMedida: 'UND',
                    totalBruto: '$-97.00',
                    descuentos: '$0.00',
                    impuestos: '$-18.00',
                    total: '$-115.00'
                },
                {
                    idItem: '021915',
                    descripcionItem: 'GEL EGO 240ml EXTREME MX',
                    cantidad: '0',
                    unidadMedida: 'UND',
                    totalBruto: '$-97.00',
                    descuentos: '$0.00',
                    impuestos: '$-18.00',
                    total: '$-115.00'
                }
            ],
            loading: false,
            error: {},
            reg: 0
        }
    );

    return(
        <div className='page-container'>
            <Title>Nota de proveedor - Detalle</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial } </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.empresa.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.usuario.razonSocial }</span></div>
                    <div><span className='info-title'>Centro de operación </span><span>001 - Supermercado Caribe</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle de la nota</SubTitle>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>DETALLE</th>
                            <th>PROVEEDOR</th>            
                            <th>DOC. CRUCE</th>
                            <th>VALOR BRUTO</th>
                            <th>DESCUENTOS</th>
                            <th>IMPUESTOS</th>
                            <th>TOTAL</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ nota.encabezado.co + " - " + nota.encabezado.tipoDocumento + '-' + nota.encabezado.numeroDocumento }</td>
                            <td>{ nota.encabezado.fechaDoc }</td>
                            <td>{ nota.encabezado.detalle }</td>
                            <td>{ nota.encabezado.terceroDescripcion }</td>
                            <td>{ nota.encabezado.documentoProveedor }</td>
                            <td>{ nota.encabezado.totalBruto }</td>
                            <td>{ nota.encabezado.descuentos }</td>
                            <td>{ nota.encabezado.impuestos }</td>
                            <td>{ nota.encabezado.totalNeto }</td>
                            <td><div className='pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>ITEM</th>
                            <th>DESCRIPCION</th>
                            <th>CANTIDAD</th>
                            <th>UNIDAD DE MEDIDA</th>
                            <th>VALOR BRUTO</th>
                            <th>DESCUENTOS</th>
                            {/*<th>PORCENTAJE IMPUESTOS</th>*/}
                            <th>VALOR IMPUESTOS</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            nota.detalles.map(
                                detalle => {
                                    console.log(detalle);
                                    return(
                                        <tr>
                                            <td>{ detalle.idItem }</td>
                                            <td>{ detalle.descripcionItem }</td>
                                            <td>{ detalle.cantidad }</td>
                                            <td>{ detalle.unidadMedida }</td>
                                            <td>{ detalle.totalBruto }</td>
                                            <td>{ detalle.descuentos }</td>
                                            {/*<td>{ Math.ceil(( (detalle.impuestos * 100) / (detalle.totalBruto - detalle.descuentos))) }</td>*/}
                                            <td>{ detalle.impuestos }</td>
                                            <td>{ detalle.total }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default NotaDetalle
