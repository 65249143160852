import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer/dist/react-pdf.es';
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin } from '../../misc/index';


const Egreso = (props) => {

    const { egreso } = props;

    return(
        <Document>
            <Page size="LETTER" style={styles.pagina}>
                <View style={styles.header}>
                    <Text style={styles.title}>{egreso.empresa.razonSocial}</Text>
                    <Text style={styles.subTitle}>Comprobante de egreso</Text>
                </View>
                <View style={styles.proveedorContainer}>
                    <Text>N.I.T.: { egreso.proveedor.nit }</Text>
                    <Text>PROVEEDOR: { egreso.proveedor.razonSocial }</Text>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.encabezadoContainer}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Documento: </Text>
                            <Text>{ egreso.encabezado.idCo + "-" + egreso.encabezado.tipoDoc + "-" + egreso.encabezado.numDoc }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha: </Text>
                            <Text>{ formatDateMin( new Date(egreso.encabezado.fechaDoc)) }</Text>
                        </View>
                        {/*<View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha de vencimiento: </Text>
                            <Text>{ egreso.encabezado.fechaVcto }</Text>
                        </View>*/}
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Detalle: </Text>
                            <Text>{ egreso.encabezado.notas }</Text>
                        </View>
                    </View>
                    <View style={styles.retencionesContainer}>
                        <Text style={styles.subTitle2}>RETENCIONES:</Text>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>CUENTA</Text>
                                <Text style={[styles.tablaCampoTitulo, { width: 170 }]}>DESCRIPCION</Text>
                                <Text style={styles.tablaCampoTitulo}>TASA</Text>
                                <Text style={styles.tablaCampoTitulo}>BASE</Text>
                                <Text style={styles.tablaCampoTitulo}>VALOR</Text>
                            </View>
                            {
                                egreso.retenciones.map(
                                    (retencion, index) => {
                                        return(
                                            <View key={index} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ retencion.idCuenta }</Text>
                                                <Text style={[styles.tablaCampo, { width: 170 }]}>{ retencion.descripcion }</Text>
                                                <Text style={styles.tablaCampo}>{ formatNumber(retencion.tasa) }%</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(retencion.valorBase) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(retencion.valor) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    <View style={styles.detalleContainer}>
                        <Text style={styles.subTitle2}>DETALLE:</Text>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>C.O.</Text>
                                <Text style={styles.tablaCampoTitulo}>CUENTA</Text>
                                <Text style={styles.tablaCampoTitulo}>DESCRIPCION CUENTA</Text>
                                <Text style={styles.tablaCampoTitulo}>DOC. CRUCE</Text>
                                <Text style={styles.tablaCampoTitulo}>DEBITOS</Text>
                                <Text style={styles.tablaCampoTitulo}>CREDITOS</Text>
                            </View>
                            {
                                egreso.detalles.map(
                                    detalle => {
                                        console.log(detalle);
                                        return(
                                            <View key={detalle.id} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ detalle.coMov }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.idCuenta }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.descripcionCuenta }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.tipoDocProv + "-" + detalle.numDocProv }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.debitos) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.creditos) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                            <View style={styles.tr}>
                                <Text style={styles.tablaCampoTotal}>TOTAL:</Text>
                                <Text style={styles.tablaCampoTotal}></Text>
                                <Text style={styles.tablaCampo}></Text>
                                <Text style={styles.tablaCampo}></Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(egreso.encabezado.debitos) }</Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(egreso.encabezado.creditos) }</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}

const EgresoPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.egreso){
            props.history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Egreso egreso={ props.history.location.state.egreso } />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 20,
        marginBottom: 10    
    },
    subTitle: {
        fontSize: 20,    
    },
    subTitle2: {
        fontSize: 10,
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        width: 85,
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        width: 85,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: 85,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    retencionesContainer: {
        marginBottom: 10 
    }
});

export default EgresoPDF;