import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import OtrosDocumentosView from './OtrosDocumentosView';

const OtrosDocumentos = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [documentos, setDocumentos ] = useState(
        {
            data: [],
            loading: true,
            error: {}
        }
    );

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getOtrosDocumentos(newEstado.proveedor.nit);

    }, []);

    const getOtrosDocumentos = (tercero) => {
        setDocumentos({
            ...documentos,
            loading: true
        });
        httpGet('/api/v1/otros-documentos/nit/' + tercero + '?pagina=0&cant=1000').then(
            resp => {
                setDocumentos({
                    ...documentos,
                    data: resp.data,
                    loading: false
                });
            }
        ).catch(
            error => {
                console.log(error);
                setDocumentos({
                    ...documentos,
                    loading: false
                });
            }
        );
    }

    return(
        <OtrosDocumentosView 
            documentos={documentos}
        />
    );
}

export default OtrosDocumentos;