import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, getYear } from '../../misc';
import './ReteIVA.css';

const ReteIVA = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

       
        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getReteIva();

        

    }, []);

    const getReteIva = async () => {
        setRetencion({
            ...retencion,
            loading: true
        });

        const estado2 = JSON.parse(sessionStorage.getItem('estado'));
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const tercero = await httpGet('/ProveedoresWS/api/proveedor/tercero?tercero=' + proveedor.nit);
        const retenciones = await httpGet('/ProveedoresWS/api/Retencion/reteiva?tercero=' + proveedor.nit + '&anno1=' + getYear(1) + '&anno2=' + getYear(0));

        console.log('TERCERO');
        console.log(tercero);

        if((retenciones !== null) && (retenciones !== undefined)){
            setRetencion({
                ...retencion,
                proveedor: tercero.data,
                data: retenciones.data,
                anno: '2020',
                loading: false
            });
        }
        
    }

    const generarPdf = async (e, rete) => {

        e.preventDefault();

        let r = {}

        const estado2 = JSON.parse(sessionStorage.getItem('estado'));
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const tercero = await httpGet('/ProveedoresWS/VerTercero?tercero=' + proveedor.nit);
        const certificado = await httpGet('/ProveedoresWS/ReteIvaDetalle?emp=CA&nit=' + proveedor.nit + '&lapsoInicial=' + rete.lapsoInicial + '&lapsoFinal=' + rete.lapsoFinal);

        console.log('CERTIFICADO');
        console.log(certificado);
        
        let totalBase = 0;
        let totalRetencion = 0;

        for( const reg of certificado.data){
            totalBase = totalBase + reg.base;
            totalRetencion = totalRetencion + reg.retencion;
        }

        let total = {
            base: totalBase,
            retencion: totalRetencion
        }

        if((certificado !== null) && (certificado !== undefined)){
            r.tipo = {
                codigo: 'RETEIVA',
                titulo: 'Certificado de retencion I.V.A.',
                periodoGravable: 'Periodo gravable: ' + rete.periodo + " " + rete.lapsoFinal.substring(0, 4),
                url: '/ProveedoresWS/ReteIvaDetalle?emp=CA&nit=' + proveedor.nit + '&lapsoInicial=' + rete.lapsoInicial + '&lapsoFinal=' + rete.lapsoFinal
            };
            r.empresa = estado2.empresa;
            r.proveedor = tercero.data;
            r.data = certificado.data;
            r.total = total;
            r.anno = '2020';

            props.history.push({ pathname: '/portal/certificado-pdf', state: { retencion: r } });
        }
        
    }

    const [retencion, setRetencion ] = useState(
        {
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [],
            loading: false,
            error: {}
        }
    );

    return(
        <div className='page-container'>
            <Title>Certificado de retención de I.V.A.</Title>
            {retencion.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
            <div className='page-body'>
                <SubTitle>Certificado de retención de I.V.A. SUPER MARKET S.A. </SubTitle>
                <div>
                    <div>SUPER MARKET S.A.</div>
                    <div>999.999.999-9</div>
                </div>
                <br />
                <div>
                    CALI
                </div>
                <br />
                <div>
                    <div><span className='info-title'>Retenido: </span><span>{ retencion.proveedor.descripcion }</span></div>
                    <div><span className='info-title'>N.I.T.: </span><span>{ retencion.proveedor.codigo }</span></div>
                    <div><span className='info-title'>Dirección </span><span>{ retencion.proveedor.direccion }</span></div>
                    <div><span className='info-title'>Ciudad </span><span>{ retencion.proveedor.ciudad }</span></div>
                </div>
                <br />
                <table className='info-table tabla-retencion-iva'>
                    <thead>
                        <tr>
                            <th>AÑO</th>
                            <th>PERIODO</th>
                            <th>BASE</th>
                            <th>RETENCION</th>
                            <th>VER</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            retencion.data.map(
                                rete => {
                                    return(
                                        <tr>
                                            <td>{ rete.lapsoFinal.substring(0,4) }</td>
                                            <td>{ rete.periodo }</td>
                                            <td>${ formatNumber(rete.base) }</td>
                                            <td>${ formatNumber(rete.retencion) }</td>                                         
                                            <td><div className='view-icon-container'><Link to={{ pathname: 'retencion', state: { type: 'RETEIVA', periodo: { lapsoInicial: rete.lapsoInicial, lapsoFinal: rete.lapsoFinal, descripcion: rete.periodo } } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { generarPdf(e, rete) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                <br />
                <br />
            </div>
        }
        </div>
    );
}

export default ReteIVA;
