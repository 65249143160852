import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './DocumentosDetalle.css';
import { formatNumber, formatDateMin } from '../../misc/index';

const DocumentosDetalle = (props) => {

    const { history: { location: { state: { documento } } } } = props
    const [ retenciones, setRetenciones ] = useState([]);
    const [ causaciones, setCausaciones ] = useState([]);
    const [ totalRetenciones, setTotalRetenciones ] = useState(0);

    useEffect(() => {

        getDocumentosDetalle(props.history.location.state.egreso);

    }, []);

    const getDocumentosDetalle = async () => {
        setDocumentos({
            ...documentos,
            loading: true
        });
        
        console.log('PROPS');
        console.log(props);
        const respCausacion = await httpGet('/api/v1/egresos/causacion/' + documento.coMov + '/' + documento.tipoDocCruce + '/' + documento.numDocCruce);
        const docs = await httpGet('/api/v1/egresos/causacion-docs/' + documento.coMov + '/' + documento.tipoDocCruce + '/' + documento.numDocCruce);
        const respRetenciones = await httpGet('/api/v1/egresos/causacion-retenciones/' + documento.coMov + '/' + documento.tipoDocCruce + '/' + documento.numDocCruce);

        setCausaciones(respCausacion.data);
        
        if((respRetenciones.data !== null) && (respRetenciones.data !== undefined)){
            let totalRetenciones = 0;
        
            for( const retencion of respRetenciones.data){
                totalRetenciones = totalRetenciones + retencion.valor;
            }

            setRetenciones(respRetenciones.data);
            setTotalRetenciones(totalRetenciones);
        }

        if((docs !== null) && (docs !== undefined)){
            setDocumentos({
                ...documentos,
                data: docs.data ,
                loading: false
            });
        }
        
    }

    const generarPdf = async (e, doc) => {
        e.preventDefault();

        const encabezado = await httpGet('/ProveedoresWS/api/nota?emp=' + sessionStorage.getItem('companyId') + '&co=' + doc.co + '&tipoDocumento=' + doc.tipoDocumento + '&numeroDocumento=' + doc.numeroDocumento);
        const detalles = await httpGet('/ProveedoresWS/api/nota/detalle?emp=' + sessionStorage.getItem('companyId') + '&co=' + doc.co + '&tipoDocumento=' + doc.tipoDocumento + '&numeroDocumento=' + doc.numeroDocumento);
        const co = await httpGet('/ProveedoresWS/api/CentroOperacion/codigo?emp=' + sessionStorage.getItem('companyId') + '&cod=' + doc.co);
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const estado = JSON.parse(sessionStorage.getItem('estado'));

        if((encabezado.data !== null) && (encabezado.data !== undefined)){

            console.log("ENCABEZADO.");
            console.log(encabezado);
            
            const doc2 = { 
                empresa: estado.empresa,
                proveedor: proveedor, 
                co: co.data,
                encabezado: encabezado.data[0], 
                detalles: detalles.data
            };
            
            
            let documento = {
                proveedor: proveedor,
                encabezado: encabezado.data[0],
                detalles: detalles.data
            }

            props.history.push({ pathname: '/portal/nota-pdf', state: {documento: doc2} });
        }
    }

    const [documentos, setDocumentos ] = useState(
        {
            data: [],
            loading: false,
            error: {}
        }
    );

    return(
        <div className='page-container'>
            <Title>Documentos de proveedor</Title>
            <div className='page-body'>
                { documentos.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                
                <SubTitle>Documento de causación</SubTitle>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>EMPRESA</th>
                            <th>CENTRO DE OPERACIÓN</th>
                            <th>FECHA</th>
                            <th>DOCUMENTO</th>                                                        
                            <th>DOCUMENTO PROVEEDOR</th>                            
                            <th>BRUTO</th>
                            <th>NETO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            causaciones.map((causacion, index) => {
                                return(
                                <tr key={index}>
                                    <td>{ causacion.empresa }</td>
                                    <td>{ causacion.co }</td>
                                    <td>{ formatDateMin( new Date(causacion.fechaDoc) )}</td>
                                    <td>{ causacion.tipoDoc + '-' + causacion.numDoc }</td>
                                    <td>{ causacion.docProv }</td>
                                    <td>${ formatNumber(causacion.totalBruto) }</td>
                                    <td>${ formatNumber(causacion.totalNeto) }</td>
                                </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                
                <br />
                <br />
                { retenciones.length > 0 &&
                <>
                <SubTitle>Retenciones</SubTitle>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CUENTA</th>
                            <th>DESCRIPCION</th>
                            <th>TASA</th>
                            <th>BASE</th>                                                        
                            <th>VALOR</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            retenciones.map(retencion => {
                                return(
                                <tr>
                                    <td>{ retencion.cuenta }</td>
                                    <td>{ retencion.cuentaDescripcion }</td>
                                    <td>{ formatNumber(retencion.tasa) }%</td>
                                    <td>${ formatNumber(retencion.valorBase) }</td>
                                    <td>${ formatNumber(retencion.valor) }</td>
                                </tr>
                                );
                            })
                        }
                        <tr>
                            <td>TOTAL:</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>${ formatNumber(totalRetenciones) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                </>
                }
                <SubTitle>Detalle del documento</SubTitle>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>FECHA</th>
                            <th>TOTAL</th>
                            <th>DETALLE</th>
                            <th>VER</th>
                            {/*<th>DESCARGAR</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            documentos.data.map(
                                documento => {
                                    return(
                                        <tr key={documento.id}>
                                            <td>{ documento.co + '-' + documento.tipoDoc + '-' + documento.numDoc }</td>
                                            <td>{ documento.numDocProv }</td>
                                            <td>{ formatDateMin( new Date(documento.fechaDoc) ) }</td>
                                            <td>${ formatNumber(documento.totalNeto) }</td>
                                            <td>{ documento.detalle }</td>
                                            <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            {/*<td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { generarPdf(e, documento) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>*/}
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                </>
                }
            </div>
        </div>
    );
}

export default DocumentosDetalle;
