import React, { useState, useEffect } from 'react';
import ConfiguracionView from './ConfiguracionView';
import { httpGet, httpPut, httpPost } from '../../../http';
import './Configuracion.css';
import { config } from '@fortawesome/fontawesome-svg-core';

const Configuracion = (props) => {

    const [ conf, setConf ] = useState({
        _id: null,
        certDay: '1'
    });

    const [ request, setRequest ] = useState({
        lading: true,
        error: false,
        errorMsg: ''
    });

    const [ reqConf, setReqConf ] = useState({
        lading: false,
        success: false,
        error: false,
        errorMsg: ''
    });
    
    useEffect(() => {
        getConf();
    }, []);

    const getConf = async () => {
        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });
        const reqConf = await httpGet('/api/v1/config');
        if(reqConf.data){
            setConf(reqConf.data[0]);
        }
        setRequest({
            ...request,
            loading: false,
            error: false,
            errorMsg: ''
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(e);

        setReqConf({
            ...reqConf,
            loading: true,
            success: false,
            error: false,
            errorMsg: ''
        });

        /*if(configuracion.siteName.length < 1){
            setReqConfiguracion({
                ...reqConfiguracion,
                loading: false,
                success: false,
                error: true,
                errorMsg: 'Debe ingresar un nombre para el sitio'
            });
            console.log("error 1");
            return 0;
        }*/

        if(conf.certDay.length < 1){
            setReqConf({
                ...reqConf,
                loading: false,
                success: false,
                error: true,
                errorMsg: 'Debe ingresar un dia'
            });
            console.log("error 2");
            return 0;
        }

        try{
            const data = {
                _id: conf._id,
                certDay: parseInt(conf.certDay)
            }
            console.log(conf);
            const reqConf = await httpPost('/api/v1/config', data);
            if(reqConf.data){
                setConf(conf);
                setReqConf({
                    ...reqConf,
                    loading: false,
                    success: true,
                    error: false,
                    errorMsg: ''
                });
                console.log("success");
            }
        } catch(error) {
            console.log(error);
            console.log("error 3");
            setReqConf({
                ...reqConf,
                loading: false,
                success: false,
                error: true,
                errorMsg: 'Ocurrio un error al guardar la configuración'
            });
        }

    }

    const handleChange = (e) => {
        setConf({
            ...conf,
            [e.target.name]: e.target.value
        });
    }

    return <ConfiguracionView conf={conf} onSubmit={handleSubmit} onChange={handleChange} request={request} reqConf={reqConf} />;
}

export default Configuracion;