import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { formatNumber, getYear, getCurrentDate } from '../../misc';
import './Retencion.css';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import Button from '../../components/button/Button';

const Fecha = (props) => {
    return(
        <div className='fecha-container'>
            <div>{props.label}</div>
            <input type="date" id="fecha" name={props.name} value={props.value} onChange={props.onChange} />
        </div>
    );
}

const Retencion = (props) => {

    const { tipo } = props.history.location.state;

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [ retenciones, setRetenciones ] = useState([]);
    const [ isChecked1, setCheked1] = useState(true);
    const [ isChecked2, setCheked2] = useState(false);
    const [ isChecked3, setCheked3] = useState(false);
    const [ request, setRequest ] = useState({
        loading: true,
        error: false,
        errorMsg: ''
    });

    useEffect(() => {

       
        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getRetencion(true);
        
    }, []);

    const getRetencion = async (modo) => {
        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });

        try{

            const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
            let resp = null;
            if(modo == 1){
                resp = await httpGet('/api/v1/retenciones/meses/' + tipo.cuenta + '/' + proveedor.nit);
            } else if (modo == 2) {
                resp = await httpGet('/api/v1/retenciones/periodos/' + tipo.cuenta + '/' + proveedor.nit);
            } else if (modo == 3) {
                resp = await httpGet('/api/v1/retenciones/annos/' + tipo.cuenta + '/' + proveedor.nit);
            }
            
            if(resp){
                setRetenciones(resp.data);
            }

            setRequest({
                ...request,
                loading: false,
                error: false,
                errorMsg: ''
            });
        } catch(error){
            console.log(error);
            setRequest({
                ...request,
                loading: false,
                error: true,
                errorMsg: ''
            });
        }
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        if(e.target.value === '1'){
            setCheked1(true);
            setCheked2(false);
            setCheked3(false);
            getRetencion(1);
        } else if(e.target.value === '2') {
            setCheked1(false);
            setCheked2(true);
            setCheked3(false);
            getRetencion(2);
        } else if(e.target.value === '3') {
            setCheked1(false);
            setCheked2(false);
            setCheked3(true);
            getRetencion(3);
        }
    }

    return(
        <div className='page-container'>
            <Title>{ tipo.descripcion }</Title>
            
            <div className='page-body'>
                {
                    request.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : request.error ?
                    <div>
                        <Alert style={{ width: '100%', marginLeft: '40px', marginTop: '50px', marginBottom: '50px', textAlign: 'center' }} variant='success'>Ocurrio un error al cargar la información</Alert>
                    </div>
                    :
                    <>
                        <br/>
                        <div>
                            <div className="retencion-radio-title">
                                Modo de visualización:
                            </div>
                            <div className="retencion-radio-container" >
                                <div><input className="retencion-radio-option" type="radio" id="mode1" name="mode" value="1" checked={isChecked1} onChange={handleChange} /><label htmlFor="mode1">Mensual</label></div>
                                <div><input className="retencion-radio-option" type="radio" id="mode2" name="mode" value="2" checked={isChecked2} onChange={handleChange} /><label htmlFor="mode2">Bimestral</label></div>
                                <div><input className="retencion-radio-option" type="radio" id="mode3" name="mode" value="3" checked={isChecked3} onChange={handleChange} /><label htmlFor="mode3" >Anual</label></div>
                                
                            </div>
                        </div>
                        <br />
                        <table className='info-table tabla-retencion'>
                            <thead>
                                <tr>
                                    <th>AÑO</th>
                                    { isChecked2 ? <th>PERIODO</th> : isChecked1 && <th>MES</th> }
                                    <th>BASE</th>
                                    <th>RETENCION</th>
                                    <th>VER DETALLE</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    retenciones.map(
                                        (retencion, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{ retencion.anno }</td>
                                                    { (isChecked2 || isChecked1) && <td>{ retencion.descripcion }</td> }
                                                    <td>${ formatNumber(retencion.base) }</td>
                                                    <td>${ formatNumber(retencion.retencion) }</td>
                                                    <td><div className='view-icon-container'><Link to={{ pathname: "/portal/retencion-detalle", state: { tipo: tipo, retencion: retencion } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                </tr>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </>
                }
            </div>
        </div>
    );
}

export default Retencion;
