import React from 'react';
import './SubTitle.css';

const SubTitle = (props) => {
    return(
        <div className='subtitle'>
            <h4>{ props.children }</h4>
        </div>
    );
}

export default SubTitle;